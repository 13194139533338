import React from "react"

import Layout from "../components/layout"
import Presentation from "../components/presentation"
import MyProject from "../components/myproject"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Presentation />
    <hr
      style={{
        width: `5%`,
        margin: `90px auto`,
        borderTop: `3px solid #f67300`,
      }}
    />
    <MyProject />
  </Layout>
)

export default IndexPage
