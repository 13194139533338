import React from 'react'

export default function Presentation() {
  return (
    <div>
      <h1>Hi,</h1>
      <p>
        I'm Quentin Bolgiani. I founded{" "}
        <a
          href="https://collectorcarvalue.com"
        >
          Collector Car Value
        </a>
        , a web service specialized in classic car valuation. I am currently in
        charge of design, frontend, backend and data processing of the whole
        project.
      </p>
      <p>
        You can find on this page a presentation of my projects.{/*and the{" "}
        <a
          href="/blog"
        >
          articles I have written
        </a>
        . Most of them can cover my main project, but also other subjects that
        are based on my curiosity and my discoveries.
      </p>
      <p>
        I'm passionate, even obsessed, with classic cars. I also like to read,
        so you can also find here some thoughts or reviews of books.*/}
  </p>
      <p>
        {" "}
        <a
          href="mailto:hello@quentinbolgiani.com"
        >
          Send me an email
        </a>{" "}
        if you wish. There you go. You have been warned.
      </p>
    </div>
  )
}
