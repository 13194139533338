import React from 'react'
import ccvBanner from '../images/blog_banner_grey_lite.png'
import ccvFiche from '../images/blog_ccv_sample.png'
import ccvSearch from '../images/sample_ccv_search.png'
import { Link } from 'gatsby'

export default function MyProject() {
  return (
    <div>
      <h2>I have founded Collector Car Value</h2>
      <p>
        <a href="https://collectorcarvalue.com">Collector Car Value
        </a> is a young bootstraped software company
        specialized in classic car valuation. Since 2017, we try to clarify the
        classic car market by providing a 10 000 cars' valuation.{" "}
      </p>
      <Link to="https://collectorcarvalue.com">
        <img src={ccvBanner}
          style={{
            border: `1px #f0f0f2 solid`,
            borderRadius: `10px`,
          }}
          alt="Collector Car Value logo"
        />
      </Link>
      <Link to="https://collectorcarvalue.com">
        <img src={ccvFiche}
          style={{
            border: `1px #f0f0f2 solid`,
            borderRadius: `10px`,
          }}
          alt="Collector Car example"
        />
      </Link>
      <Link to="https://collectorcarvalue.com">
        <img src={ccvSearch}
          style={{
            border: `1px #f0f0f2 solid`,
            borderRadius: `10px`,
          }}
          alt="Collector Car Value search"
        />
      </Link>
    </div>
  )
}
